<template>
    <div>
        <v-container v-if="!signupSent && !error" class="align-center d-flex flex-column" :class="[isPaymentForm ? 'pa-0' : 'px-5 pb-6']">
            <v-form ref="form" @submit.prevent="submit" lazy-validation class="ma-5" :class="{ 'ma-0 full-width': isPaymentForm }">
                <template v-if="!isPaymentForm">
                    <v-row>
                        <v-col class="shrink d-flex justify-center" cols="12">
                            <h2 class="text-no-wrap mt-6 mb-3">Sign Up for a Free 30-day Trial</h2>
                            <!-- <h2 class="text-no-wrap mt-6 mb-3">Sign Up for a Free 7-day Trial</h2> -->
                        </v-col>
                    </v-row>
                    <v-row class="text-center">
                        <v-col
                            ><v-icon class="" size="60">mdi-bell</v-icon>
                            <p class="">Keep Up To Date With The Latest News</p></v-col
                        >
                        <v-col
                            ><v-icon class="" size="60">mdi-calendar-month</v-icon>
                            <p>Create And Save Your Own Schedules</p>
                        </v-col>
                        <v-col
                            ><v-icon class="" size="60">mdi-image-multiple</v-icon>
                            <p>Access To Our Range of Images</p></v-col
                        >
                    </v-row>
                </template>

                <v-row dense>
                    <v-col md="5" cols="12">
                        <v-text-field
                            ref="firstName"
                            v-model="firstName"
                            :disabled="loading"
                            required
                            label="First name"
                            :rules="[rules.required]"
                            class="pt-0 my-5"
                            placeholder="Enter your first name..."
                        >
                        </v-text-field>
                    </v-col>
                    <v-spacer />
                    <v-col md="5" cols="12">
                        <v-text-field
                            ref="lastName"
                            v-model="lastName"
                            :disabled="loading"
                            required
                            label="Last name"
                            :rules="[rules.required]"
                            class="pt-0 my-5"
                            placeholder="Enter your last name..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col md="5" cols="12">
                        <v-text-field
                            ref="email"
                            v-model="email"
                            type="email"
                            :disabled="loading"
                            label="Email Address"
                            required
                            :rules="[rules.required, rules.email]"
                            class="pt-0 my-5"
                            placeholder="Enter your email..."
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="0" md="2"> </v-col>
                    <!-- <v-col md="5" cols="12">
            <v-text-field
              ref="phoneNumber"
              v-model="phoneNumber"
              type="phone"
              :disabled="loading"
              label="Phone Number"
              required
              :rules="[rules.required, rules.phoneNumber]"
              class="pt-0 my-5 "
              
              placeholder="Enter your phone number..."
            >
            </v-text-field>
          </v-col> -->
                    <v-col cols="12">
                        <!-- <v-autocomplete
                            ref="country"
                            v-model="country"
                            :disabled="loading"
                            label="Country"
                            :items="countries"
                            item-text="name"
                            item-value="code"
                            class="pt-0 mt-0"
                            outlined
                           
                            placeholder="Select your country..."
                            return-object
                        >
                            <template v-slot:item="{ item }">
                                <v-icon class="mr-4">{{ item.emoji }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <template v-slot:selection="{ item }">
                                <v-icon class="mr-4">{{ item.emoji }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete> -->
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col md="5" cols="12">
                        <v-text-field
                            ref="password"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            :disabled="loading"
                            label="Password"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                            required
                            :rules="[rules.required, rules.minLength6]"
                            class="pt-0 my-5"
                            placeholder="Enter a password..."
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="0" md="2"> </v-col>
                    <v-col md="5" cols="12">
                        <v-text-field
                            :type="showPassword ? 'text' : 'password'"
                            v-model="confirmPassword"
                            :disabled="loading"
                            label="Confirm password"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                            required
                            :rules="[rules.isMatch]"
                            class="pt-0 my-5"
                            placeholder="Confirm password..."
                        ></v-text-field>
                    </v-col>
                </v-row>
                <template v-if="!isPaymentForm">
                    <v-row>
                        <v-spacer />
                        <v-col cols="6">
                            <v-btn block x-large type="submit" :loading="loading">
                                <span>Sign Up</span>
                            </v-btn>
                        </v-col>
                        <v-spacer />
                    </v-row>
                    <v-row>
                        <v-col cols="12" align="center" justify="center">
                            <v-btn :to="{ name: 'auth.login' }" small text class="my-5" :disabled="loading">
                                <span class="text-capitalize font-weight-regular">Already have an account?</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-form>
        </v-container>

        <v-container px-5 pb-6 v-else-if="signupSent && !error" class="primary--text">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Sign Up</h2>
                    <p class="text-muted text-center grey--text">You have signed up successfully!</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large rounded dark :to="{ name: 'auth.login' }"> Back to Log in </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container px-5 pb-6 v-else-if="error" class="">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Sign Up</h2>
                    <p class="text-muted text-center">An error occured while trying to sign you up. Please try again.</p>
                    <p class="text-center mb-0">
                        <b>{{ errorText }}</b>
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-spacer />
                <v-col cols="6">
                    <v-btn block x-large @click="error = false"> Back </v-btn>
                </v-col>
                <v-spacer />
            </v-row>
            <v-row>
                <v-spacer />
                <v-col cols="6">
                    <v-btn block :to="{ name: 'auth.login' }" small text>
                        <span class="text-capitalize font-weight-regular">Already have an account?</span>
                    </v-btn>
                </v-col>
                <v-spacer />
            </v-row>
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import UserMixin from "@/mixins/user-mixin";

export default {
    mixins: [UserMixin],
    meta: {
        titleTemplate: "Sign Up | %s",
    },

    props: {
        isPaymentForm: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            errorText: "happy",
            loading: false,
            signupSent: false,
            error: false,
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            country: {},
            phoneNumber: "",
            showPassword: false,
            rules: {
                required: (value) => !!value || "This field is required",
                minLength3: (value) => value.length >= 3 || "Min 3 characters",
                minLength4: (value) => value.length >= 4 || "Min 4 characters",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                phoneNumber: (value) => value.length >= 8 || "Min 8 characters",
                isMatch: (value) => value === this.confirmPassword,
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },
    computed: {
        ...mapState(["assets", "headerCache"]),
        email: {
            set(email) {
                this.$store.commit("email", email);
            },
            get(email) {
                return this.$store.getters.email;
            },
        },
        date7days() {
            let currentDate = new Date();
            let futureDate = new Date();
            futureDate.setDate(currentDate.getDate() + 7);
            return futureDate.toISOString();
        },
        date30daysFromNow() {
            let currentDate = new Date();
            let futureDate = new Date();
            futureDate.setDate(currentDate.getDate() + 30);
            return futureDate.toISOString();
        },
    },
    methods: {
        submit() {
            var self = this;

            //Check if there is an application
            var applicationContext = self.application && self.application.authenticationStyle == "application";

            self.loading = true;

            console.log("Sign up to application");
            self.$fluro.auth
                .signup(
                    {
                        username: self.email,
                        firstName: self.firstName,
                        lastName: self.lastName,
                        password: self.password,
                        // phoneNumber: self.phoneNumber,
                        confirmPassword: self.confirmPassword,
                        isPremium: false,
                    },
                    {
                        bypassInterceptor: true,
                        application: applicationContext, //Sign up to the application rather than Fluro itself
                    }
                )
                .then(signupSuccess, signupFail);

            //////////////////////////////////

            async function signupSuccess(res) {
                var user = res.data;
                console.log("Signup success", user);

                let freeTrial = null;

                //On Successful Signup, if persona successfully created, create free trial
                if (user?.persona) {
                    console.warn("New persona ID", user.persona);

                    if (self.isPaymentForm) {
                        self.$fluro.auth.sync();
                        self.$emit("signupSuccess", user);
                        self.loading = false;
                    } else {
                        try {
                            //1. Create Free Trial
                            freeTrial = await self.createFreeTrial(user.persona);

                            //2.Redeem Free Trial
                            let redeem = await self.redeem(freeTrial?.redemptionCode, user?.persona).then((redeem) => redeem);
                            console.log("Free Trial Redeemed", redeem);

                            //3. Check if purchase(Free Trial) had been redeemed and update Membership status on front-end
                            await self.$app.checkPremium(user).then((res) => {
                                console.warn("Updated USER (after premium check)", res?.isPremium);
                                if (res) {
                                    //Update the RSHeader which displays membership status
                                    self.$store.commit("generalSet", {
                                        key: "headerCache",
                                        data: self.headerCache + 1,
                                    });
                                }

                                self.$router.push({ path: "/home" });
                                self.loading = false;
                            });
                        } catch (err) {
                            self.error = true;
                            self.errorText = err?.message;
                            console.log("Free Trial ERROR!", err);
                            self.loading = false;
                        }
                    }
                }

                // // self.$store.commit('user', res.data);

                // let contact = user.contacts;

                // if (contact && contact.length) {
                //   let contactId = contact[0];
                //   // let payload = {
                //   //   data: {
                //   //     country: self.country,
                //   //   },
                //   // };
                //   // let updated = await Contact.update(contactId, payload).then(({ data }) => data);
                // } else {
                //   console.log("Could not find contact");
                // }

                // self.$router.push({ path: "/home" });

                // self.loading = false;
            }

            //////////////////////////////////

            function signupFail(err) {
                var message = self.$fluro.utils.errorMessage(err);
                if (self.isPaymentForm) {
                    self.$emit("signupError", {
                        errorMessage: message,
                    });
                    self.loading = false;
                } else {
                    self.error = true;
                    self.errorText = message;
                    console.log("ERROR!", message, err);
                    self.loading = false;
                }
            }
        },
        async createFreeTrial(userId) {
            //7 Day Trial
            // var purchase_payload = {
            //     title: "7-day Free Trial",
            //     managedOwners: userId,
            //     persona: userId,
            //     realms: ["59028fc41745ab6c2fefeb02"],
            //     product: {
            //         _id: "64262004c18abe7229db9f4c",
            //         owners: userId,
            //     },
            //     renew: false,
            //     expires: true,
            //     expiryDate: this.date7days,
            // };
            // let freeTrial = await this.$fluro.api
            //     .post("/reaction/spark/642b5e367186fa59f6d396f2", purchase_payload)
            //     .then(({ data }) => data)
            //     .catch((err) => console.warn("CREATE FREE TRIAL error?", err));
            // console.log("Reaction Message:", freeTrial?.data?.message);
            // return freeTrial;

            //30 Day Trial
            var purchase_payload = {
                title: "30-day Free Trial",
                managedOwners: userId,
                persona: userId,
                realms: ["59028fc41745ab6c2fefeb02"],
                product: {
                    _id: "64262004c18abe7229db9f4c",
                    owners: userId,
                },
                renew: false,
                expires: true,
                expiryDate: this.date30daysFromNow,
            };
            let freeTrial = await this.$fluro.api
                .post("/reaction/spark/642b5e367186fa59f6d396f2", purchase_payload)
                .then(({ data }) => data)
                .catch((err) => console.warn("CREATE FREE TRIAL error?", err));
            console.log("Reaction Message:", freeTrial?.data?.message);
            return freeTrial;
        },
        async checkPersonaHasTrial(userId) {
            if (userId) {
                const query = {
                    _type: "purchase",
                    status: "active",
                    // managedPurchaser: user.persona,
                    managedOwners: { $in: [userId] },
                    product: "64262004c18abe7229db9f4c",
                };

                const freeTrials = await this.$fluro.api
                    .post("/content/_query", query, {
                        params: {
                            noCache: true,
                        },
                    })
                    .then(({ data }) => {
                        return data;
                    })
                    .catch((err) => console.log(err));

                console.log("Check if user has had a Free Trial (Query Result):", freeTrials);

                return freeTrials.length > 0;
            } else {
                console.warn("No User ID provided!");
            }
        },
        // async checkPremium(user) {
        //     //Mongo params to pass into fluro
        //     const query = {
        //         _type: "purchase",
        //         status: "active",
        //         managedPurchaser: user.persona,
        //         // managedOwners: { $in: [user.persona] },
        //         "data.personaId": user.persona,
        //         expiryDate: {
        //             $gte: new Date(),
        //         },
        //         product: {
        //             //Purchase product must be one of the following,
        //             //7-day Free Trial: 64262004c18abe7229db9f4c
        //             //Annual Subscription: 641a756e82c6a4039eb70811
        //             //Monthly Subscription: 641a75c582c6a4039eb7081b
        //             $in: ["64262004c18abe7229db9f4c", "641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
        //         },
        //     };

        //     const purchases = await this.$fluro.api
        //         .post("/content/_query", query, {
        //             params: {
        //                 noCache: true,
        //             },
        //         })
        //         .then(({ data }) => {
        //             return data;
        //         })
        //         .catch((err) => console.log(err));

        //     console.log("ManagedOwner's PURCHASES (Signup.vue)):", purchases);

        //     if (purchases.length) {
        //         user.isPremium = true;
        //     } else {
        //         user.isPremium = false;
        //     }
        //     return user;
        // },
        async redeem(code, personaId) {
            const redeem = await this.$fluro.api.post("/redeem", {
                recipient: personaId,
                redemptionCode: code,
            });
            console.log("Free Trial Redeem (signup):", redeem);
            return redeem;
        },
    },
    async mounted() {
        // console.log("User has Free Trials?", await this.checkPersonaHasTrial(this.$app.user));
        // console.log("Expiry Dtae:", this.date7days);
        // console.log("$Fluro", this.$fluro);
        // console.log(this.assets.user.userCircle);
        if (!this.firstName || !this.firstName.length) {
            return this.$refs.firstName.focus();
        }

        if (!this.phoneNumber || !this.phoneNumber.length) {
            return this.$refs.phoneNumber.focus();
        }

        if (!this.lastName || !this.lastName.length) {
            return this.$refs.lastName.focus();
        }

        if (!this.email || !this.email.length) {
            return this.$refs.email.focus();
        }

        if (!this.password || !this.password.length) {
            return this.$refs.password.focus();
        }
    },
};
</script>

<style lang="scss">
// .white-label label {
//   color: white !important;
//   border-color: white;
// }
// .white-button button {
//   color: white !important;
//   border-color: white;
// }

// .white-text input {
//   color: white !important;
//   border-color: white;
// }
// .v-text-field > .v-input__control > .v-input__slot:before {
//   border-color: white !important;
//   border-style: solid;
//   border-width: thin 0 0 0;
// }
</style>
